/*
 * Copyright © 2020-2023 Ocean Galaxy Inc. All Rights Reserved.
 * @Description:
 * @LastEditors: caoran
 * @LastEditTime: 2024-09-29 14:12:39
 */
import { createApp } from 'vue'
import 'lib-flexible'
import App from './App.vue'
import { createRouter, createWebHistory } from 'vue-router'

const app = createApp(App)

const routes = [
  {
    path: '/',
    component: () => import('./home')
  },
  {
    path: '/timemark-camera',
    component: () => import('./home')
  },
  {
    path: '/timemark-camera/privacyTerms',
    component: () => import('./privacyTerms')
  },
  {
    path: '/timemark-camera/download',
    component: () => import('./components/download')
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { top: 0 }
  }
})

app.use(router)
app.mount('#app')
